import * as React from "react";

export interface MobileNavigationInitialStateInterface {
  menu_items?: [];
  isUserLoggedIn?: boolean;
  country?: string;
}

interface MobileNavigationInitialStateConsumerInterface {
  initialState: MobileNavigationInitialStateInterface;
}

const defaultValue = {
  initialState: {} as MobileNavigationInitialStateInterface,
} as unknown as MobileNavigationInitialStateConsumerInterface;

const MobileNavigationInitialStateContext = React.createContext(defaultValue);

interface MobileNavigationInitialStateProviderProps {
  mountElement: Element | null;
}

export const MobileNavigationInitialStateProvider: React.FunctionComponent<
  MobileNavigationInitialStateProviderProps
> = (props) => {
  const initialState = React.useMemo(() => {
    const mountElement = (props.mountElement || {}) as HTMLElement;

    const initialStateJsonString =
      mountElement?.dataset.reactMobileNavigation__initialstate || "{}";

    // console.log(mountElement.dataset.reactMobileNavigation__initialstate);

    return JSON.parse(
      initialStateJsonString,
    ) as MobileNavigationInitialStateInterface;
  }, [props.mountElement]);

  return (
    <MobileNavigationInitialStateContext.Provider value={{ initialState }}>
      {props.children}
    </MobileNavigationInitialStateContext.Provider>
  );
};

export const useMobileNavigationInitialState = () => {
  const context = React.useContext(MobileNavigationInitialStateContext);
  if (context === defaultValue) {
    throw new Error(
      "useMobileNavigationInitialState must be used within a <MobileNavigationInitialStateProvider>",
    );
  }
  return context;
};

import * as React from "react";

export interface CountryChangeMenuProps {
  CountryChangeMenuOpen?: boolean;
  setCountryChangeMenuOpen: (value: boolean) => void;
  selectedCountry?: string;
  // setSelectedCountry: (value: string) => void;
}

export const CountryChangeMenu = ({
  CountryChangeMenuOpen,
  setCountryChangeMenuOpen,
  selectedCountry,
  // setSelectedCountry,
}: CountryChangeMenuProps) => {
  const [isCountryLoading, setIsCountryLoading] = React.useState(false);
  const [selectedCountryValue, setSelectedCountryValue] = React.useState("");

  const countryRedirects: Record<string, string> = {
    "New Zealand": "https://www.bedbathntable.co.nz/?redirected=nz",
    Australia: "https://www.bedbathntable.com.au/?redirected=au",
    Singapore: "https://www.bedbathntable.com.sg/?redirected=sg",
  };

  const handleCountryClick = (selectedCountryValue: string) => {
    if (selectedCountry !== selectedCountryValue) {
      setIsCountryLoading(true);
      setSelectedCountryValue(selectedCountryValue);

      try {
        const redirectUrl = countryRedirects[selectedCountryValue];

        if (redirectUrl) {
          window.location.href = redirectUrl;
          setIsCountryLoading(true);
        } else {
          console.warn("No redirect URL defined for this country");
        }
      } catch (error) {
        console.error("Error handling country click:", error);
      }
    }
  };

  return (
    <div
      className={`country-change-second-menu ${CountryChangeMenuOpen ? "open" : ""}`}
    >
      <div className="sub-mobile-inner-top">
        <div
          onClick={() => {
            setCountryChangeMenuOpen(false);
          }}
          className="sub-mobile-menu-left-arrow"
        ></div>
        <div className="sub-mobile-menu-topic">Where do you shop?</div>
        <div className="sub-mobile-menu-close"></div>
      </div>
      <div className="country-change-second-menu-middle">
        As we only ship locally, make sure you choose the right country of
        delivery.
      </div>
      <div className="country-change-second-menu-bottom">
        <div className="country-list">
          <div
            className={`country-option ${selectedCountry === "Australia" ? "selected" : ""}`}
            onClick={() => handleCountryClick("Australia")}
          >
            <span className="flag-au-menu"></span>
            <span className="country-name">Australia</span>
            {isCountryLoading && selectedCountryValue === "Australia" ? (
              <span className="circle-loader-mobile-nav"></span>
            ) : (
              <span
                className={`checkmark ${selectedCountry !== "Australia" ? "invisible" : ""}`}
              >
                ✔
              </span>
            )}
          </div>
          <div
            className={`country-option ${selectedCountry === "New Zealand" ? "selected" : ""}`}
            onClick={() => handleCountryClick("New Zealand")}
          >
            <span className="flag-nz-menu"></span>
            <span className="country-name">New Zealand</span>
            {isCountryLoading && selectedCountryValue === "New Zealand" ? (
              <span className="circle-loader-mobile-nav"></span>
            ) : (
              <span
                className={`checkmark ${selectedCountry !== "New Zealand" ? "invisible" : ""}`}
              >
                ✔
              </span>
            )}
          </div>
          <div
            className={`country-option ${selectedCountry === "Singapore" ? "selected" : ""}`}
            onClick={() => handleCountryClick("Singapore")}
          >
            <span className="flag-sg-menu"></span>
            <span className="country-name">Singapore</span>
            {isCountryLoading && selectedCountryValue === "Singapore" ? (
              <span className="circle-loader-mobile-nav"></span>
            ) : (
              <span
                className={`checkmark ${selectedCountry !== "Singapore" ? "invisible" : ""}`}
              >
                ✔
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import * as React from "react";
import { SubMenu } from "./mobile-navigation-menu";

export interface SubMobileNavigationMenuProps {
  secondMenuOpen?: boolean;
  setSecondMenuOpen: (value: boolean) => void;
  currentMenuOpened: SubMenu[];
  subMenuTitle: string;
}

export const SubMobileNavMenu = ({
  secondMenuOpen,
  setSecondMenuOpen,
  currentMenuOpened,
  subMenuTitle,
}: SubMobileNavigationMenuProps) => {
  const closeSubMenu = () => {
    setSecondMenuOpen(false);
  };

  return (
    <div className={`sub-mobile-nav-main ${secondMenuOpen ? "open" : ""}`}>
      <div className="sub-mobile-inner-top">
        <div
          onClick={closeSubMenu}
          className="sub-mobile-menu-left-arrow"
        ></div>
        <div className="sub-mobile-menu-topic">{subMenuTitle}</div>
        <div className="sub-mobile-menu-close"></div>
      </div>
      <div className="sub-mobile-inner-middle">
        View All{" "}
        {subMenuTitle.charAt(0).toUpperCase() +
          subMenuTitle.slice(1).toLowerCase()}
      </div>
      <div className="sub-mobile-inner-bottom">
        <div className="sub-mobile-inner-bottom-topic">
          {subMenuTitle === "SALE" ? subMenuTitle : `${subMenuTitle} SALE`}
        </div>
        <div className="sub-mobile-outer-ul">
          <ul className="sub-mobile-menu-ul">
            {currentMenuOpened.map((item, index) => (
              <li key={index} className="sub-mobile-inner-li-topic">
                <div className="sub-mobile-inner-li-topic-name">
                  {item.title}
                </div>
                <ul className="sub-mobile-inner-ul">
                  {item.submenu_items.map((subitem) => (
                    <li
                      className="sub-mobile-inner-li-item"
                      id="sub-menu-inner-link"
                      onMouseDown={(e) => {
                        if (e.button !== 0) {
                          e.preventDefault();
                          return;
                        }
                        window.location.href = subitem.href;
                      }}
                    >
                      {subitem.text}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

import * as React from "react";
import { MobileNavigationMenu } from "./mobile-navigation-menu";
import { MobileNavigationInitialStateProvider } from "./providers/mobile-navigation-initial-state-provider";

interface AppProps {
  mountElement: Element;
}

export const App: React.FunctionComponent<AppProps> = (props) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);
  const [shouldCloseSubMenu, setShouldCloseSubMenu] = React.useState(true);

  React.useEffect(() => {
    const mobileNavHamburgerBtnSelect = document.querySelector(
      ".hamburger.hamburger--squeeze, .hamburger.hamburger--squeeze.is-active",
    );

    const inputFieldSelect = document.getElementById("search");
    const submenuLinkSelect = document.getElementById("sub-menu-inner-link");
    const columnClassSelect = document.querySelector(
      ".columns",
    ) as HTMLElement | null;

    const mobileNavAnchorTagSelect = document.querySelectorAll(
      ".mobile-nav-info-container a, .mobile-nav-gift-card-main a",
    );
    const bodyHTMLSelect = document.getElementById("html-body");

    const toggleNavMenu = (event: any) => {
      if (window.location.pathname !== "/gift-cards") {
        setIsMobileNavOpen(!isMobileNavOpen);
        setShouldCloseSubMenu(true);
        event.preventDefault();
        event.stopPropagation();
      }
      if (window.location.pathname === "/gift-cards") {
        if (columnClassSelect instanceof HTMLElement) {
          if (!isMobileNavOpen) {
            columnClassSelect.style.display = "block";
            columnClassSelect.style.visibility = "visible";
            setTimeout(() => {
              setIsMobileNavOpen(true);
              setShouldCloseSubMenu(true);
            }, 50);
          } else {
            setIsMobileNavOpen(false);
            setShouldCloseSubMenu(true);
            setTimeout(() => {
              columnClassSelect.style.display = "none";
              columnClassSelect.style.visibility = "hidden";
            }, 400);
          }
        }
      }
    };

    const handleNavAnchorTagClick = (event: any) => {
      setIsMobileNavOpen(false);
      setShouldCloseSubMenu(true);
    };

    if (isMobileNavOpen || !shouldCloseSubMenu) {
      bodyHTMLSelect?.classList.add("no-scroll-body-for-mobile-nav");
    } else {
      bodyHTMLSelect?.classList.remove("no-scroll-body-for-mobile-nav");
    }

    mobileNavHamburgerBtnSelect?.addEventListener("click", toggleNavMenu);
    inputFieldSelect?.addEventListener("click", () => {
      setIsMobileNavOpen(false);
      setShouldCloseSubMenu(true);
    });

    submenuLinkSelect?.addEventListener("click", () => {
      setShouldCloseSubMenu(true);
      setIsMobileNavOpen(false);
    });

    mobileNavAnchorTagSelect.forEach((link) =>
      link.addEventListener("click", handleNavAnchorTagClick),
    );

    return () => {
      mobileNavHamburgerBtnSelect?.removeEventListener("click", toggleNavMenu);
      inputFieldSelect?.removeEventListener("click", () => {
        setIsMobileNavOpen(false);
      });
      mobileNavAnchorTagSelect.forEach((link) =>
        link.removeEventListener("click", handleNavAnchorTagClick),
      );
      submenuLinkSelect?.removeEventListener("click", () => {
        setShouldCloseSubMenu(true);
        setIsMobileNavOpen(false);
      });
      bodyHTMLSelect?.classList.remove("no-scroll-body-for-mobile-nav");
    };
  }, [isMobileNavOpen, shouldCloseSubMenu]);

  return (
    <div>
      <MobileNavigationInitialStateProvider mountElement={props.mountElement}>
        <MobileNavigationMenu
          isMobileNavOpen={isMobileNavOpen}
          shouldCloseSubMenu={shouldCloseSubMenu}
          setShouldCloseSubMenu={setShouldCloseSubMenu}
        />
      </MobileNavigationInitialStateProvider>
    </div>
  );
};

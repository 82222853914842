import * as React from "react";
import { useMobileNavigationInitialState } from "../providers/mobile-navigation-initial-state-provider";
import { SubMobileNavMenu } from "./sub-mobile-nav-menu";
import { CountryChangeMenu } from "./country-change-menu";

export interface MobileNavigationMenuProps {
  isMobileNavOpen?: boolean;
  shouldCloseSubMenu: boolean;
  setShouldCloseSubMenu: (value: boolean) => void;
}

export interface MenuItem {
  label: string;
  href: string;
  submenu?: SubMenu[];
}

export interface SubMenu {
  title: string;
  submenu_items: SubMenuItem[];
}

export interface SubMenuItem {
  text: string;
  href: string;
}

export const MobileNavigationMenu = ({
  isMobileNavOpen,
  shouldCloseSubMenu,
  setShouldCloseSubMenu,
}: MobileNavigationMenuProps) => {
  const [secondMenuOpen, setSecondMenuOpen] = React.useState(false);
  const [currentMenuOpened, setCurrentMenuOpened] = React.useState<SubMenu[]>(
    [],
  );
  const [CountryChangeMenuOpen, setCountryChangeMenuOpen] =
    React.useState(false);
  const [subMenuTitle, setSubMenuTitle] = React.useState<string>("");

  const { initialState } = useMobileNavigationInitialState();
  const menuItems: MenuItem[] = initialState?.menu_items || [];
  const isUserLoggedIn = initialState?.isUserLoggedIn;
  const country = initialState?.country;
  const [selectedCountry, setSelectedCountry] = React.useState(country);

  React.useEffect(() => {
    setSelectedCountry(country);
  }, [country]);

  const handleMainItemClick = (submenu: SubMenu[], title: string) => {
    setSecondMenuOpen(true);
    setCurrentMenuOpened(submenu);
    setSubMenuTitle(title);
    setShouldCloseSubMenu(false);
  };

  const handleCountryChange = () => {
    setCountryChangeMenuOpen(true);
    setShouldCloseSubMenu(false);
  };

  React.useEffect(() => {
    if (shouldCloseSubMenu) {
      setSecondMenuOpen(false);
      setCountryChangeMenuOpen(false);
    }
  }, [shouldCloseSubMenu]);

  return (
    <>
      <div className="mobile-nav-main">
        <div className={`drawer ${isMobileNavOpen ? "open" : ""}`}>
          <div className="drawer-inner">
            <div className="mobile-nav-info-container">
              <div className="mobile-nav-info-card">
                <a href="/sales/guest/form">
                  <div className="mobile-nav-track-order-icon"></div>
                  <div>Track Order</div>
                </a>
              </div>
              <div className="mobile-nav-info-card">
                <a href="/support/home">
                  <div className="mobile-nav-help-icon"></div>
                  <div>Help Centre</div>
                </a>
              </div>
              <div className="mobile-nav-info-card">
                <a href="/locator">
                  <div className="mobile-nav-locator-icon"></div>
                  <div>Store Locator</div>
                </a>
              </div>
            </div>
            <div className="mobile-nav-gift-card-main">
              <a href="/gift-cards">
                <div className="mobile-nav-gift-card-text">
                  SHOP OUR E-GIFT CARDS
                </div>
              </a>
            </div>
            <ul className="drawer-menu">
              {menuItems.map((item, index) => {
                const isActive = window.location.pathname === item.href;
                return (
                  <li
                    key={index}
                    className={`drawer-item ${index < 2 ? "header" : ""} ${isActive ? "active-menu" : ""}`}
                    onClick={() => {
                      if (item.submenu) {
                        handleMainItemClick(item.submenu, item.label);
                      } else {
                        setSecondMenuOpen(false);
                      }
                    }}
                  >
                    <a href="#">
                      {item.label} <span className="drawer-item-arrow"></span>
                    </a>
                  </li>
                );
              })}
              <div className="country-change-outer">
                <div
                  className="country-change-inner"
                  onClick={() => {
                    handleCountryChange();
                  }}
                >
                  <span
                    className={`flag ${selectedCountry === "Australia" ? "au" : selectedCountry === "New Zealand" ? "nz" : selectedCountry === "Singapore" ? "sg" : ""}`}
                  ></span>
                  You are shopping in {selectedCountry}.
                  <span className="highlight">Change</span>
                </div>
              </div>
            </ul>

            {!isUserLoggedIn ? (
              <div className="drawer-footer">
                <a href="/customer/account">
                  <button className="drawer-footer-btn">
                    <span className="mobile-nav-sign-icon"></span>SIGN IN OR
                    JOIN REWARDS
                  </button>
                </a>
              </div>
            ) : (
              <div className="drawer-footer">
                <button className="drawer-footer-btn-hide"></button>
              </div>
            )}
          </div>
        </div>
      </div>
      <SubMobileNavMenu
        secondMenuOpen={secondMenuOpen}
        setSecondMenuOpen={setSecondMenuOpen}
        currentMenuOpened={currentMenuOpened}
        subMenuTitle={subMenuTitle}
      />
      <CountryChangeMenu
        CountryChangeMenuOpen={CountryChangeMenuOpen}
        setCountryChangeMenuOpen={setCountryChangeMenuOpen}
        selectedCountry={selectedCountry}
        // setSelectedCountry={setSelectedCountry}
      />
    </>
  );
};
